import React from 'react'
import Carousel from '../components/Carousel'
import About from '../components/About'
import Navbar from '../layouts/Navbar'

function Home() {
  return (
    <>
      <Navbar></Navbar>
      <Carousel></Carousel>
      <About></About>
    </>
  )
}

export default Home